.Positions {
  position: absolute;
  opacity: 0;
  z-index: 2000;
  text-align: left;
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.175);
  transform: scale(0.9) translateY(-1px);
}

.Positions[data-state="entering"] {
  opacity: 0;
  transform: scale(0.9) translateY(-20px);
}

.Positions[data-state="entered"] {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.Positions[data-state="exiting"] {
  opacity: 0;
  transform: scale(1) translateY(-3px);
}
