.css-kdijfp-Wrapper {
    flex-direction: column-reverse !important;
}
.css-13r8eji-TimelineContentDetailsWrapper {
    font-size: 1rem;

}

.css-omdvlv-TimelineItemContentWrapper {
    filter: none  !important;
}

.css-i2v06r-TimelineContentDetails {
    font-size: 22px  !important;
    font-family: 'poppins-regular' !important;
    line-height: 2.2rem;
}

.customTheme {
    color: #000000 !important;
    background-color: #E9E9E9 !important;
}
.customTheme.place-top::after {
    border-top-color: #E9E9E9 !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
}

/* testbed */
.css-1gqu14n {
    flex-direction: column-reverse !important;
}
