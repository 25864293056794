.react-tabs__tab-list {
    border: none;
    padding: 0 4rem;
}


.react-tabs__tab--selected {
    background: none;
    /* border-color: #aaa; */
    color: #f5821e;
    /* border-radius: 5px 5px 0 0; */
    border-bottom: 6px solid #f5821e !important;
}

.react-tabs__tab:focus {
    box-shadow: none;
    border: none;
}

.react-tabs__tab:focus:after {
    background: #f5821e;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1rem 5rem;
    cursor: pointer;
    font-size: 25px;
}

.carousel.carousel-slider {
    overflow: visible;
}

.carousel .control-dots {
    margin-bottom: -2.5rem;
}

.carousel .control-dots .dot {
    background: #c4c4c4;
}

.carousel.carousel-slider .control-arrow:hover {    
    background: none;
}

.carousel .control-next.control-arrow:before {
    border-left: 20px solid #f5821e;
}

.carousel .control-prev.control-arrow:before {
    border-right: 20px solid #f5821e;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.carousel .slide iframe {
    width: 649px;
    margin: 0;
    border-radius: 10px;
}

.carousel .slide img {
    width: 14%;
}

/* .carousel .slider-wrapper.axis-horizontal .slider {
    margin-top: -20px;
} */

@media only screen and (max-width: 800px) {
    .carousel .slide iframe {
        max-width: 100%;
    }
    .carousel .slide img {
        width: 65%;
    }
    .react-tabs__tab-list {
        text-align: center;
    }
}
