html {
  background: #eff2f5;
  font-size: 14px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  -ms-overflow-style: -ms-autohiding-scrollbar;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

body.modal-open {
  overflow: hidden;
}

a.nav-link {
  text-decoration: none;
  font-weight: 600;
  color: #1f4160;
  -webkit-tap-highlight-color: transparent;
}

a.nav-link:hover {
  color: #016cd1;
}

a.primary-link {
  text-decoration: none;
  color: #016cd1;
  -webkit-tap-highlight-color: transparent;
}
