.rc-dropdown {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
}
.rc-dropdown-hidden {
  display: none;
}
.rc-dropdown-menu {
  outline: none;
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 2px 0 0 0;
  max-width: 300px;
  max-height: 400px;
  overflow: scroll;
  text-align: left;
  background-color: #fff;
  border-radius: 3px;
  background-clip: padding-box;
}
.rc-dropdown-menu > li {
  margin: 0;
  padding: 0;
}
.rc-dropdown-menu:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.01);
}
.rc-dropdown-menu > .rc-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 7px 10px;
  clear: both;
  font-size: 12px;
  font-weight: normal;
  color: #666666;
  width: 100%;
  white-space: nowrap;
}
.rc-dropdown-menu > .rc-dropdown-menu-item:hover,
.rc-dropdown-menu > .rc-dropdown-menu-item-active,
.rc-dropdown-menu > .rc-dropdown-menu-item-selected {
  outline: none;
  background: #e7f3ff;
  color: #0384fe;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-selected {
  position: relative;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-selected:after {
  content: "\e613";
  font-family: "anticon";
  font-weight: bold;
  position: absolute;
  top: 6px;
  right: 16px;
  color: #3cb8f0;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-disabled {
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-disabled:hover {
  color: #ccc;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-dropdown-menu > .rc-dropdown-menu-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.rc-dropdown-menu > .rc-dropdown-menu-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  line-height: 0;
}
.rc-dropdown-slide-up-enter,
.rc-dropdown-slide-up-appear {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rc-dropdown-slide-up-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
  -webkit-animation-name: rcDropdownSlideUpIn;
  animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
  -webkit-animation-name: rcDropdownSlideDownIn;
  animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
  -webkit-animation-name: rcDropdownSlideUpOut;
  animation-name: rcDropdownSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
  -webkit-animation-name: rcDropdownSlideDownOut;
  animation-name: rcDropdownSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;

    transform: scaleY(0.9);
  }
  100% {
    opacity: 1;

    transform: scaleY(1);
  }
}

@keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;

    transform: scaleY(1);
  }
  100% {
    opacity: 0;

    transform: scaleY(0.9);
  }
}

@keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;

    transform: scaleY(0.9);
  }
  100% {
    opacity: 1;

    transform: scaleY(1);
  }
}
@keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;

    transform: scaleY(1);
  }
  100% {
    opacity: 0;

    transform: scaleY(0.9);
  }
}

.rc-dropdown {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
  font-size: 12px;
  font-weight: normal;
  /* line-height: 1.5; */
}

.rc-dropdown-menu {
  outline: none;
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 0;
  text-align: left;
  font-family: SF UI Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
  background-clip: padding-box;
  width: 215px;
  box-sizing: border-box;
  padding: 5px 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  font-size: 14px;
  text-align: left;
  list-style: none;
  border: 1px solid rgb(213, 222, 230);
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px, rgba(0, 0, 0, 0.02) 0px 8px 24px;
  border-radius: 5px;
}

.rc-dropdown-menu:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.01);
}

.rc-dropdown-hidden {
  display: none;
}
